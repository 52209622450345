import React from "react";
import { Router } from "@reach/router";

import { ClientOnly } from "../../components";
import { ProtectedRoute } from "../../core/auth";

import Login from "./login";
import Checkout from "./checkout";
import LoginCallback from "./login/callback";
import Logout from "./logout";
import { Helmet } from "react-helmet";

const Wallet = () => (
  <ClientOnly>
    <Helmet title="Latitude Digital Wallet" defer={false} />
    <Router basepath="/wallet">
      <LoginCallback path="/login/callback" />

      <ProtectedRoute
        mode={ProtectedRoute.MODE.CUSTOMER}
        path="/checkout"
        as={Checkout}
        SignInComponent={Login}
      />

      <Logout path="/logout" />
    </Router>
  </ClientOnly>
);

export default Wallet;
