import React from "react";
import { Message, Stack } from "basis";

import { resetAuth } from "../../core/auth";
import { Container } from "../../components";
import { Scene } from "../../layout/wallet";

const Logout = () => {
  resetAuth();

  return (
    <Scene>
      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="10 6" maxWidth={800}>
          <Stack gap="4">
            <Message
              severity="warning-or-significant"
              bg="secondary.pink.t30"
              title="Invalid session"
            >
              You have been logged out because of an invalid session or you do
              not have access to the requested resource.
              <br />
              <br />
              We've been notified and looking into this with the utmost urgency.
              <br />
              Contact the retailer if problem persists.
            </Message>
          </Stack>
        </Container>
      </Container>
    </Scene>
  );
};

export default Logout;
